import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import config from "./config";

export const userPool = new CognitoUserPool({
  UserPoolId: config.cognito.userPoolId,
  ClientId: config.cognito.clientId,
});

export const createUser = (username, email, password, callback) => {
  const attributeList = [
    new CognitoUserAttribute({
      Name: "email",
      Value: email,
    }),
  ];
  userPool.signUp(username, password, attributeList, null, callback);
};

export const verifyUser = (username, verifyCode, callback) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);
  cognitoUser.confirmRegistration(verifyCode, true, callback);
};

export const authenticateUser = (email, password, callback) => {
  const authData = {
    Username: email,
    Password: password,
  };
  const authDetails = new AuthenticationDetails(authData);
  const userData = {
    Username: email,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  cognitoUser.authenticateUser(authDetails, {
    onSuccess: (result) => {
      // console.log("Access token:", result.getAccessToken().getJwtToken());

      // Ensure session is retrieved and valid
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error("Error retrieving session:", err);
          callback(err);
          return;
        }
        callback(null, result); // Successful login
      });
    },
    onFailure: (err) => {
      if (err.code === "UserNotConfirmedException") {
        console.warn("User not confirmed:", err);
        callback({
          message: "Please verify your email to continue.",
          code: err.code,
        });
        return;
      }
      console.error("Authentication error:", err);
      callback(err); // Handle other authentication errors
    },
  });
};

// Step 1: Initiate Password Reset
export const initiatePasswordReset = (email, callback) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  cognitoUser.forgotPassword({
    onSuccess: (result) => {
      // console.log('Code sent successfully:', result);
      callback(null, result);
    },
    onFailure: (err) => {
      console.error("Error initiating password reset:", err);
      callback(err);
    },
  });
};

// Step 2: Complete Password Reset
export const confirmPasswordReset = (
  email,
  verificationCode,
  newPassword,
  callback
) => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: () => {
      // console.log("Password reset successful");
      callback(null, "Password reset successful");
    },
    onFailure: (err) => {
      console.error("Password reset failed:", err);
      callback(err);
    },
  });
};

export const signOut = () => {
  userPool.getCurrentUser().signOut();
  window.location.reload();
};

export const getCurrentUser = async () => {
  try {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      console.warn("❌ No user found in getCurrentUser");
      return false;
    }

    const session = await new Promise((resolve, reject) => {
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          console.warn("❌ Invalid session:", err || "Session expired.");
          reject(false);
        } else {
          resolve(session);
        }
      });
    });

    const userId = session.getAccessToken().payload.username;
    // console.log("✅ User ID:", userId);

    // Get attributes for extra validation
    const attributes = await new Promise((resolve, reject) => {
      cognitoUser.getUserAttributes((err, attrs) => {
        if (err) {
          console.error("⚠️ Error retrieving attributes:", err);
          reject(false);
        } else {
          // console.log("✅ User attributes:", attrs);
          resolve(attrs);
        }
      });
    });

    return userId || false;
  } catch (error) {
    console.error("❌ Critical error in getCurrentUser:", error);
    return false;
  }
};
