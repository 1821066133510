import { useMemo } from "react";
import config from "../config";
import useWebSocket from "react-use-websocket";

export const useChatSocket = (userId, onMessage) => {
  const socketUrl = useMemo(
    () => `${config.websocketUrl}?cognitoId=${userId}`,
    [userId]
  );
  const { sendMessage, readyState } = useWebSocket(
    socketUrl,
    {
      shouldReconnect: (event) => userId != null,
      share: true,
      onMessage,
    },
    userId != null
  );

  return {
    sendMessage: (message) => {
      if (readyState !== WebSocket.OPEN) {
        alert("Cannot send message. Service not connected.");
        return false;
      }
      sendMessage(JSON.stringify(message));
      return true;
    },
    readyState,
  };
};
