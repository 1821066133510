import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaBluesky } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { SiPlaycanvas } from "react-icons/si";
import "../styles/Footer.css";
import { BASE_PATH } from "../utils/constants";

function Footer() {
  return (
    <footer className="footer">
      {/* <div className="footer__brand">
        <Link to={`${BASE_PATH}`} className="header__logo">
          <SiPlaycanvas />
          PressPlay
        </Link>
      </div> */}

      <div className="footer__contact">
        <p>
          Say hello: <a href="mailto:hello@pressplay.gg">hello@pressplay.gg</a>
        </p>
      </div>

      <div className="footer__social">
        <p>Follow us:</p>
        <a
          href="https://www.facebook.com/people/PressPlay/61571478637455/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
        <a href="https://bsky.app/profile/did:plc:wk4gm44swnf5s26xjlrwe7le" target="_blank" rel="noopener noreferrer">
          <FaBluesky />
         </a>
        <a
          href="https://www.instagram.com/pressplay_gg/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/pressplay-gg/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </a>
      </div>
      <div className="footer__copyright">
        <p>&copy; 2025 PressPlay. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
