import { useGetCurrentUserQuery } from "./APIUtil";
import { userPool } from "../Cognito";

export const useCurrentUser = () => {
  const cognitoUser = userPool.getCurrentUser();
  const { data } = useGetCurrentUserQuery(undefined, {
    skip: !cognitoUser,
  });

  return data;
};
