import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "../styles/FindTeam.css";
import { getCurrentUser } from "../Cognito";
import { Link } from "react-router-dom";
import config from "../config";
import Spinner from "../components/Spinner";
import { BASE_PATH } from "../utils/constants";
import FilterDropdown from "../components/FilterDropdown";
import { useChatSocket } from "../utils/chatSocketUtil";
import { useStartConversationMutation } from "../utils/APIUtil";
import { toast } from "react-toastify";

function FindTeam({ isChatExpanded }) {
  const [userId, setUserId] = useState(null);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedTools, setSelectedTools] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [userConnections, setUserConnections] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isAddingConnection, setIsAddingConnection] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { sendMessage } = useChatSocket(userId);
  const [
    startConversation,
    {
      isSuccess: isStartConversationSuccess,
      error: startConversationError,
      isLoading: isStartConversationLoading,
    },
  ] = useStartConversationMutation();

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const handleToggleDropdown = (dropdown) => {
    console.log(dropdown);
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleRemoveFilter = (filterToRemove) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = prevFilters.filter(
        (filter) =>
          filter.category !== filterToRemove.category ||
          filter.option !== filterToRemove.option
      );

      const filtered = profiles.filter((profile) => {
        const groupedFilters = updatedFilters.reduce((acc, filter) => {
          acc[filter.category] = acc[filter.category] || [];
          acc[filter.category].push(filter.option);
          return acc;
        }, {});

        return Object.entries(groupedFilters).every(([category, options]) => {
          const fieldMapping = {
            Specialty: profile.specialty,
            Location: profile.location,
            Tools: profile.tools,
            Experience: profile.experience,
          };
          if (category === "Tools") {
            return options.every((option) =>
              fieldMapping[category]?.includes(option)
            );
          }

          return options.some((option) =>
            fieldMapping[category]?.includes(option)
          );
        });
      });

      setFilteredProfiles(filtered);
      return updatedFilters;
    });
  };

  const handleAddFilter = (category, option) => {
    setSelectedFilters((prevFilters) => {
      const isDuplicate = prevFilters.some(
        (filter) => filter.category === category && filter.option === option
      );

      if (isDuplicate) {
        return prevFilters;
      }

      const updatedFilters = [...prevFilters, { category, option }];

      const groupedFilters = updatedFilters.reduce((acc, filter) => {
        acc[filter.category] = acc[filter.category] || [];
        acc[filter.category].push(filter.option);
        return acc;
      }, {});

      const matchesFilters = (profile) => {
        return Object.entries(groupedFilters).every(([category, options]) => {
          const fieldMapping = {
            Specialty: profile.specialty,
            Location: profile.location,
            Tools: profile.tools,
            Experience: profile.experience,
          };

          if (category === "Tools") {
            return options.every((option) =>
              fieldMapping[category]?.includes(option)
            );
          }

          return options.some((option) =>
            fieldMapping[category]?.includes(option)
          );
        });
      };

      const filtered = profiles.filter(matchesFilters);

      setFilteredProfiles(filtered);
      return updatedFilters;
    });
  };

  useEffect(() => {
    const checkAuth = async () => {
      console.log("🔍 Checking authentication...");
      setIsLoading(true);

      try {
        const retrievedUserId = await getCurrentUser();

        if (retrievedUserId) {
          setUserId(retrievedUserId);
          setShowAuthPopup(false);

          AWS.config.update({
            region: config.aws.region,
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: config.aws.identityPoolId,
            }),
          });

          await new Promise((resolve, reject) => {
            AWS.config.credentials.get((err) => {
              if (err) {
                console.error("⚠️ AWS Credentials Error:", err);
                reject(err);
              } else {
                resolve();
              }
            });
          });

          await fetchUserConnections(retrievedUserId);
        } else {
          setShowAuthPopup(true);
        }
      } catch (error) {
        setShowAuthPopup(true);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  const fetchUserConnections = async (userId) => {
    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: config.aws.usersTableName,
      Key: {
        cognitoId: userId,
      },
    };

    try {
      const data = await dynamodb.get(params).promise();
      if (data.Item && data.Item.connections) {
        setUserConnections(data.Item.connections);
      } else {
        setUserConnections(""); // Set as empty if no connections exist
      }
    } catch (error) {
      console.error("Error fetching user connections:", error);
    }
  };

  const addToConnections = async () => {
    setIsAddingConnection(true); // Start connection action
    const newConnectionId = selectedProfile.id;
    const connectionArray = userConnections ? userConnections.split(",") : [];

    if (connectionArray.includes(newConnectionId)) {
      alert(`${selectedProfile.name} is already in your connections.`);
      setIsAddingConnection(false);
      return;
    }

    const updatedConnections =
      connectionArray.length > 0
        ? `${userConnections},${newConnectionId}`
        : newConnectionId;

    const dynamodb = new AWS.DynamoDB.DocumentClient();
    const params = {
      TableName: config.aws.usersTableName,
      Key: { cognitoId: userId },
      UpdateExpression: "SET connections = :connections",
      ExpressionAttributeValues: {
        ":connections": updatedConnections,
      },
    };

    try {
      await dynamodb.update(params).promise();
      setUserConnections(updatedConnections);
      alert(`${selectedProfile.name} has been added to your connections.`);
    } catch (error) {
      console.error("Error adding to connections:", error);
    }
    setIsAddingConnection(false); // End connection action
  };

  const isConnected = (profileId) => {
    const connectionArray = userConnections ? userConnections.split(",") : [];
    return connectionArray.includes(profileId);
  };

  useEffect(() => {
    const fetchProfilesFromDynamoDB = async () => {
      if (!userId) return;

      setIsLoading(true); // Start loading
      AWS.config.update({
        region: config.aws.region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: config.aws.identityPoolId,
        }),
      });

      const dynamodb = new AWS.DynamoDB.DocumentClient();
      const params = {
        TableName: config.aws.usersTableName,
      };

      try {
        const data = await dynamodb.scan(params).promise();

        const shuffledProfiles = data.Items.filter(
          (item) =>
            item.cognitoId !== userId && item.name && item.bio && item.photoUrl
        )
          .map((item) => ({
            id: item.cognitoId,
            name: item.name,
            specialty: item.skills || "Specialty Not Provided",
            location: item.location || "Location Not Provided",
            goals: item.bio,
            imageUrl: item.photoUrl,
            tools: item.tools || "Tools Not Provided",
            experience: item.experience || "Experience not provided",
            lookingFor: item.lookingFor || "",
            whatIBring: item.whatIBring || "",
            hoursAvailable: item.hoursAvailable || "",
          }))
          .sort(() => Math.random() - 0.5);

        setProfiles(shuffledProfiles);
        setFilteredProfiles(shuffledProfiles);
      } catch (error) {
        console.error("Error fetching profiles from DynamoDB:", error);
      }
      setIsLoading(false);
    };

    fetchProfilesFromDynamoDB();
  }, [userId]);

  useEffect(() => {
    if (startConversationError) {
      toast(startConversationError.data?.error, {
        position: "top-left",
        className: "toast-message",
      });
    }
  }, [startConversationError]);

  const openPopup = (profile) => {
    setSelectedProfile(profile);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedProfile(null);
  };

  return (
    <>
      {showAuthPopup && (
        <div className="auth-popup-overlay">
          <div className="auth-popup">
            <h2>Find Your Team</h2>
            <p>
              To protect the privacy of our users you need to be logged in to
              access this page.
            </p>
            <div className="auth-popup-buttons">
              <Link to={`${BASE_PATH}/login`} className="auth-button">
                Log In
              </Link>
              <Link to={`${BASE_PATH}/sign-up`} className="auth-button">
                Sign Up Free
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className={`find-team ${showAuthPopup ? "blurred" : ""}`}>
        <h1>Find a Team</h1>
        <button className="toggle-filters-button" onClick={toggleFilters}>
          {filtersVisible ? "Hide Filters" : "Show Filters"}
        </button>

        {filtersVisible && (
          <div className="filters">
            <FilterDropdown
              options={[
                "Development",
                "Design",
                "Project Management",
                "QA Testing",
                "Mobile",
                "Narrative",
                "2D Art",
                "3D Art",
                "Level Design",
                "Character Design",
                "Animation",
                "Sound Design",
                "Soundtrack & Scoring",
                "AI",
                "VR/AR",
              ]}
              onSelect={(option) => handleAddFilter("Specialty", option)}
              isOpen={activeDropdown === "specialty"}
              onToggle={() => handleToggleDropdown("specialty")}
              filterOption={"Specialty"}
            />
            <FilterDropdown
              options={[
                "USA",
                "Canada",
                "UK",
                "Germany",
                "France",
                "Sweden",
                "Finland",
                "Denmark",
                "Japan",
                "South Korea",
                "Australia",
                "New Zealand",
                "Poland",
                "Netherlands",
                "Spain",
                "Italy",
                "Czech Republic",
                "Austria",
                "Brazil",
                "Mexico",
                "Argentina",
                "Singapore",
                "India",
                "Ireland",
                "Belgium",
                "Switzerland",
                "Norway",
              ]}
              onSelect={(option) => handleAddFilter("Location", option)}
              isOpen={activeDropdown === "location"}
              onToggle={() => handleToggleDropdown("location")}
              filterOption={"Location"}
            />
            <FilterDropdown
              options={[
                "Hobbyist",
                "Indie",
                "AAA",
                "OG",
                "Award Winner",
                "Game Jams",
                "Shipped Games",
              ]}
              onSelect={(option) => handleAddFilter("Experience", option)}
              isOpen={activeDropdown === "experience"}
              onToggle={() => handleToggleDropdown("experience")}
              filterOption={"Experience"}
            />
            <FilterDropdown
              options={[
                "Unreal Engine",
                "Unity",
                "Godot",
                "CryEngine, RPG Maker",
                "GameMaker Studio",
                "Blender",
                "Maya",
                "3ds Max",
                "ZBrush",
              ]}
              onSelect={(option) => handleAddFilter("Tools", option)}
              isOpen={activeDropdown === "tools"}
              onToggle={() => handleToggleDropdown("tools")}
              filterOption={"Tools"}
            />
          </div>
        )}

        {filtersVisible && (
          <div className="bubbles-container-team">
            {selectedFilters.map((filter, index) => (
              <div
                key={index}
                className={`bubble category-${filter.category.toLowerCase()}`}
              >
                {filter.option}
                <button
                  className="close-btn"
                  onClick={() => handleRemoveFilter(filter)}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}

        {isLoading ? (
          <Spinner size="large" message="Fetching profiles..." />
        ) : (
          <div
            className={`profile-grid ${isChatExpanded ? "chat-expanded" : ""}`}
          >
            {filteredProfiles.map((profile) => (
              <div key={profile.id} className="profile-card">
                <div
                  className={`profile-card__image-container ${
                    isConnected(profile.id) ? "connected" : ""
                  }`}
                >
                  <img
                    src={profile.imageUrl}
                    alt={profile.name}
                    className="profile-card__image"
                  />
                </div>
                <h3 className="profile-card__name">{profile.name}</h3>
                <p className="profile-card__specialty">{profile.specialty}</p>
                <p className="profile-card__location">📍 {profile.location}</p>
                <p className="profile-card__goals">{profile.goals}</p>
                <button
                  className="quick-view-button"
                  onClick={() => openPopup(profile)}
                >
                  Quick View
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {showPopup && selectedProfile && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-card" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>
              X
            </button>
            <div className="profile-picture-container">
              <img
                src={selectedProfile.imageUrl}
                alt={selectedProfile.name}
                className="profile-picture"
              />
            </div>
            <h3 className="popup-card__name">{selectedProfile.name}</h3>
            {selectedProfile.specialty && (
              <p>
                <strong>Specialty</strong> {selectedProfile.specialty}
              </p>
            )}
            {selectedProfile.location && (
              <p>
                <strong>Location</strong> {selectedProfile.location}
              </p>
            )}
            {selectedProfile.goals && (
              <p>
                <strong>Goals</strong> {selectedProfile.goals}
              </p>
            )}
            {selectedProfile.lookingFor && (
              <p>
                <strong>Who I want to work with</strong>{" "}
                {selectedProfile.lookingFor}
              </p>
            )}
            {selectedProfile.whatIBring && (
              <p>
                <strong>What I bring</strong> {selectedProfile.whatIBring}
              </p>
            )}
            {selectedProfile.experience && (
              <p>
                <strong>Experience</strong> {selectedProfile.experience}
              </p>
            )}
            {selectedProfile.hoursAvailable && (
              <p>
                <strong>Time</strong> {selectedProfile.hoursAvailable}
              </p>
            )}
            {isConnected(selectedProfile.id) ? (
              <button
                className="send-message-button"
                onClick={() => {
                  if (!selectedProfile) {
                    alert("No connection selected");
                    return;
                  }
                  startConversation({
                    recipientId: selectedProfile.id,
                    initialMessage: `Hi ${selectedProfile.name}, let's connect!`,
                  });
                }}
                disabled={isStartConversationLoading}
              >
                Send Message
              </button>
            ) : (
              <button
                className="add-to-connections-button"
                onClick={addToConnections}
              >
                + Add to Connections
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FindTeam;
