import '../styles/Blog.css';

function Blog() {
  // Generate dummy blog data
  const blogPosts = Array.from({ length: 9 }, (_, index) => ({
    id: index,
    title: generateRandomTitle(),
    imageUrl: `https://picsum.photos/200/200?random=${index}`
  }));

  return (
    <div className="blog">
      <h1>Blog</h1>
      <div className="blog__grid">
        {blogPosts.map((post) => (
          <div key={post.id} className="blog__tile">
            <img src={post.imageUrl} alt={post.title} className="blog__image" />
            <h3 className="blog__title">{post.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

// Function to generate random titles related to game development
function generateRandomTitle() {
  const titles = [
    "How to Get Started in Game Development",
    "Top 5 Game Engines for Beginners",
    "Understanding Game Mechanics and Design",
    "Building Your First RPG Game",
    "Tips for Creating Immersive Storylines",
    "Level Design Basics for Game Developers",
    "Game Development Trends to Watch in 2024",
    "Optimizing Performance in Mobile Games",
    "Balancing Difficulty in Puzzle Games",
    "Creating Engaging Characters for Games",
    "Breaking into the Indie Game Industry",
    "A Beginner’s Guide to 3D Modeling"
  ];
  return titles[Math.floor(Math.random() * titles.length)];
}

export default Blog;
