import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import '../styles/FilterDropdown.css';

function FilterDropdown({ options, onSelect, isOpen, onToggle, filterOption }) {
  const handleSelect = (option) => {
    onSelect(option); // Notify parent of selection
    onToggle(); // Close the dropdown
  };

  return (
    <div className="dropdown-container">
      <div
        className="dropdown-select"
        onClick={onToggle}
        tabIndex={0}
        onKeyDown={(e) => e.key === "Enter" && onToggle()}
      >
        {filterOption} {/* Always display the default filterOption */}
        <FaChevronDown />
      </div>
      {isOpen && (
        <div className="dropdown-options">
          {options.map((option) => (
            <div
              key={option}
              className="dropdown-option"
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FilterDropdown;
