import React, { useState, useEffect } from "react";
import ConversationList from "./ConversationList";
import ActiveConversation from "./ActiveConversation";
import "../styles/Sidebar.css";
import Spinner from "./Spinner";
import { useCurrentUser } from "../utils/APIHooks";
import { useGetConversationsQuery } from "../utils/APIUtil";

const Sidebar = ({ onToggle }) => {
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [notifications, setNotifications] = useState({});
  const currentUser = useCurrentUser();
  const { data, isLoading, error } = useGetConversationsQuery({
    skip: currentUser == null,
  });

  const handleSelectConversation = (conversation) => {
    if (selectedConversation?.convoId === conversation.convoId) {
      setSelectedConversation(null);
      onToggle(false);
    } else {
      setSelectedConversation(conversation);
      onToggle(true);

      setNotifications((prev) => {
        const updated = { ...prev };
        delete updated[conversation.convoId];
        return updated;
      });
    }
  };

  const handleCloseConversation = () => {
    setSelectedConversation(null);
  };

  const handleNewMessage = (convoId) => {
    if (!selectedConversation || selectedConversation.convoId !== convoId) {
      setNotifications((prev) => ({
        ...prev,
        [convoId]: (prev[convoId] || 0) + 1,
      }));
    }
  };

  // **Conditional Rendering Logic**
  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!data || data.conversations.length === 0) {
    console.warn("📭 No conversations to display.");
    return null;
  }

  return (
    <div className="messaging-sidebar-container">
      <div className="profile-list">
        <ConversationList
          conversations={data.conversations}
          onSelectConversation={handleSelectConversation}
          notifications={notifications}
        />
      </div>

      {selectedConversation && currentUser && (
        <div className="expanded-sidebar">
          <ActiveConversation
            convoId={selectedConversation.convoId}
            recipientId={selectedConversation.userId}
            name={selectedConversation.name}
            photoUrl={selectedConversation.photoUrl}
            onNewMessage={handleNewMessage}
            currentUserName={currentUser.name}
            currentUserPhoto={currentUser.photoUrl}
            onCloseConversation={handleCloseConversation}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;
