import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Spinner from "./Spinner";
import { useChatSocket } from "../utils/chatSocketUtil";
import { useCurrentUser } from "../utils/APIHooks";
import api, {
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
} from "../utils/APIUtil";
import { useDispatch } from "react-redux";

const ActiveConversation = ({
  convoId,
  recipientId,
  name,
  photoUrl,
  onNewMessage,
  currentUserName,
  currentUserPhoto,
  onCloseConversation,
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  // const [invalidateOnExit, setInvalidateOnExit] = useState(false);
  const messagesEndRef = useRef(null); // Scroll Reference
  const currentUser = useCurrentUser();
  const { data: messageData, isFetching } = useGetMessagesQuery(convoId, {
    skip: !convoId || currentUser == null,
  });
  // const dispatch = useDispatch();
  // const [getMessages, { data: messageData, isFetching }] =
  //   useLazyGetMessagesQuery();
  const { sendMessage, readyState } = useChatSocket(
    currentUser?.cognitoId,
    useCallback(
      (event) => {
        const response = JSON.parse(event.data);
        const { data, action } = response;

        switch (action) {
          case "chat_update": {
            if (data.convoId === convoId) {
              console.log("chat update");
              setMessages((prev) => [...prev, data]);
              // should invalidate the message cache when this window exits
              // setInvalidateOnExit(true);
            }

            onNewMessage(data.convoId);
            break;
          }
          default:
            break;
        }
      },
      [setMessages, convoId]
    )
  );

  const isConnected = useMemo(
    () => readyState === WebSocket.OPEN,
    [readyState]
  );

  useEffect(() => {
    if (messageData) {
      setMessages(messageData.messages);
    }
  }, [messageData]);

  // Scroll to the latest message when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [messages, isFetching]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const messageDate = formatDate(message.timestamp);
      if (!groups[messageDate]) {
        groups[messageDate] = [];
      }
      groups[messageDate].push(message);
      return groups;
    }, {});
  };

  const handleSendMessage = () => {
    if (!newMessage.trim() || !isConnected || !currentUser?.cognitoId) return;

    const message = {
      action: "chat",
      convoId,
      senderId: currentUser.cognitoId,
      recipientId,
      message: newMessage,
    };

    try {
      // setMessages((prev) => [
      //   ...prev,
      //   { ...message, senderId: currentUserId, senderName: currentUserName },
      // ]);
      sendMessage(message);
      setNewMessage("");

      // socket.send(JSON.stringify(message));
    } catch (error) {
      console.error("Failed to send message via WebSocket:", error);
      alert("Failed to send message. Check your connection.");
    }
  };

  return (
    <div className="chat-window">
      {convoId ? (
        isFetching ? (
          <Spinner />
        ) : (
          <>
            <div className="chat-header">
              <h4>Chat with {name}</h4>
              <button className="close-button" onClick={onCloseConversation}>
                X
              </button>
            </div>
            <div className="chat-messages">
              {Object.entries(groupMessagesByDate(messages)).map(
                ([date, messagesForDate]) => (
                  <div key={date} className="message-group">
                    <div className="date-divider">{date}</div>
                    {messagesForDate.map((message, index) => (
                      <div
                        key={index}
                        className={`chat-message ${
                          message.senderId === currentUser?.cognitoId
                            ? "chat-sent"
                            : "chat-received"
                        }`}
                      >
                        <img
                          src={
                            message.senderId === currentUser?.cognitoId
                              ? currentUserPhoto
                              : photoUrl
                          }
                          alt={`${
                            message.senderId === currentUser?.cognitoId
                              ? currentUserName
                              : name
                          }'s profile`}
                          className="chat-message-profile"
                        />
                        <div className="chat-message-content">
                          <div className="chat-message-header">
                            <span className="chat-message-sender">
                              {message.senderId === currentUser?.cognitoId
                                ? currentUserName
                                : name}
                            </span>
                            <span className="chat-message-timestamp">
                              {formatTimestamp(message.timestamp)}
                            </span>
                          </div>
                          <div className="chat-message-text">
                            {message.message}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              )}
              <div ref={messagesEndRef} />
            </div>
            <div className="message-input-container">
              <textarea
                className="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
              ></textarea>
              <button className="send-button" onClick={handleSendMessage}>
                Send
              </button>
            </div>
          </>
        )
      ) : (
        <p>No Messages</p>
      )}
    </div>
  );
};

export default ActiveConversation;
