import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import config from "../config";
import screenshot0 from "../imgs/editprofile6-screenshot.png";
import screenshot1 from "../imgs/findateam-screenshot.png";
import screenshot2 from "../imgs/messaging-screenshot.png";
import meme1 from "../imgs/meme1.png";
import logo from "../imgs/pressplay-high-resolution-logo-grayscale-transparent.png";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Waitlist.css";

class Waitlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      redirect: false,
      isMobile: window.innerWidth <= 768,
      modalImage: null,
      showArrow: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  handleScroll = () => {
    console.log("scrolling");
    // const scrollY = window.scrollY; // Current vertical scroll position
    // const windowHeight = window.innerHeight; // Height of the viewport
    // console.log(scrollY, windowHeight);
    // Hide the arrow when the user scrolls past the first section
    // this.setState({ showArrow: scrollY < windowHeight });
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEmailRequest = () => {
    const email = "hello@pressplay.gg";
    const subject = "Alpha Access Request";
    const body = "I'm interested in alpha access!";
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    const { email, name } = this.state;

    // Basic email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      toast("Please enter a valid email address!", {
        position: "top-left",
        className: "toast-message",
      });
      return;
    }

    if (!name) {
      toast("Please enter your name!", {
        position: "top-left",
        className: "toast-message",
      });
      return;
    }

    try {
      const response = await fetch("https://api.brevo.com/v3/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "api-key":
            "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",
        },
        body: JSON.stringify({
          email: email,
          attributes: {
            NAME: name,
          },
        }),
      });

      if (response.ok) {
        toast(
          "Thanks! You've been added to the waitlist, keep an eye out for updates in your inbox.",
          {
            position: "top-left",
            className: "toast-message",
          }
        );
        this.setState({
          email: "",
          name: "",
        });
      } else {
        throw new Error("Failed to submit");
      }
    } catch (error) {
      console.error("Error saving contact to Brevo:", error);
      toast(
        "Oops! Something went wrong saving your info. Please try again later.",
        {
          position: "top-left",
          className: "toast-message",
        }
      );
    }
  };

  render() {
    const { redirect, email, name, showArrow } = this.state;

    if (redirect) {
      return <Navigate to="/find-team" />;
    }

    return (
      <div>
        <div className="container-15">
          <div className="header__brand"></div>
          <section className="home__hero-waitlist">
            <div id="container15" className="home__hero-bg">
              <p
                style={{
                  marginTop: "50px",
                  marginBottom: "0",
                  color: "#606060",
                  fontSize: "1.75rem",
                  fontWeight: "800",
                  fontVariant: "all-small-caps",
                }}
                className="home__hero-waitlist_R1R1"
              >
                Join the Indie Game Creator Community
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "3.5rem",
                  fontWeight: "800",
                  color: "#393939",
                  fontVariant: "all-small-caps",
                }}
                className="home__hero-waitlist_R1R2"
              >
                Team Up
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "3.5rem",
                  fontWeight: "800",
                  color: "#393939",
                  fontVariant: "all-small-caps",
                }}
                className="home__hero-waitlist_R1R4"
              >
                Make Games
              </p>
              <p
                style={{
                  margin: "0",
                  fontSize: "1.75rem",
                  fontWeight: "800",
                  color: "#606060",
                  fontVariant: "all-small-caps",
                  paddingTop: "10px",
                  paddingBottom: "0px",
                }}
                className="home__hero-waitlist_R1R3"
              >
                Matchmaking for game devs & artists
              </p>
              <p
                style={{
                  color: "#6B62FF",
                  fontSize: "1.5rem",
                  fontWeight: "800",
                  fontVariant: "all-small-caps",
                  paddingBottom: "10px",
                  paddingTop: "0px",
                }}
                className="home__hero-waitlist_R1R5"
              >
                Launching March 2025 - It's dangerous to go alone
              </p>
            </div>
          </section>
          <div
            className={
              this.state.isMobile
                ? "waitlist-container-mobile"
                : "waitlist-container"
            }
          >
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
            />

            <h2 className="waitlist-header">Join Our Waitlist</h2>
            <p
              style={{
                fontWeight: "600",
                fontSize: ".8rem",
                textAlign: "left",
                marginTop: "0",
                color: "#606060",
                fontVariant: "normal",
              }}
            >
              Drop your info below to be one of the first to try out Pressplay.
              Get early access to the beta and start building something awesome.
            </p>
            <form onSubmit={this.handleWaitlistSubmit} className="signup-form">
              <div style={{ marginBottom: "0" }} className="form-group">
                <input
                  className="form-input"
                  name="name"
                  value={name}
                  placeholder="Name"
                  type="text"
                  onChange={this.handleInputChange}
                  required
                  style={{ marginBottom: "1rem" }}
                />
                <input
                  className="form-input email-input"
                  name="email"
                  value={email}
                  placeholder="Email"
                  type="email"
                  onChange={this.handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <button type="submit" className="submit-button">
                  Sign me up!
                </button>
                <p
                  style={{
                    fontSize: ".8rem",
                    textAlign: "left",
                    color: "#606060",
                    fontVariant: "normal",
                  }}
                >
                  By signing up, you'll get reminder emails and the occasional
                  updates from us. Don't worry, you can opt out whenever you
                  want.
                </p>
              </div>
            </form>
          </div>
          {showArrow && (
            <div className="scroll-arrow">
              <div className="arrow"></div>
            </div>
          )}
        </div>
        {/* How It Works Section */}
        <div
          style={{
            backgroundColor: "#6B62FF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="container-16"
        >
          <div className="additional-content">
            <div className={this.state.isMobile ? "team-up-mobile" : "team-up"}>
              <div className="team-up-text">How It Works</div>
            </div>
            <div
              className={this.state.isMobile ? "image-row-mobile" : "image-row"}
            >
              <div className="how-it-works-step">
                <img
                  src={screenshot0}
                  alt="Create Your Profile"
                  className="side-by-side-image"
                  onClick={() => this.setState({ modalImage: screenshot0 })}
                />
                <h3>CREATE YOUR PROFILE</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "600",
                  }}
                >
                  Share your skills, passions and project ideas.
                </p>
              </div>
              <div className="how-it-works-step">
                <img
                  src={screenshot1}
                  alt="Find Your Team"
                  className="side-by-side-image"
                  onClick={() => this.setState({ modalImage: screenshot1 })}
                />
                <h3>FIND YOUR TEAM</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "600",
                  }}
                >
                  Use filters to connect with developers, designers, and
                  artists.
                </p>
              </div>
              <div className="how-it-works-step">
                <img
                  src={screenshot2}
                  alt="Build Together"
                  className="side-by-side-image"
                  onClick={() => this.setState({ modalImage: screenshot2 })}
                />
                <h3>BUILD TOGETHER</h3>
                <p
                  style={{
                    marginLeft: "60px",
                    marginRight: "60px",
                    fontWeight: "600",
                  }}
                >
                  Collaborate on projects and bring your game to life.
                </p>
              </div>
            </div>
          </div>
        </div>

        {this.state.modalImage && (
          <div
            className="image-modal"
            onClick={() => this.setState({ modalImage: null })}
          >
            <img
              src={this.state.modalImage}
              alt="Enlarged view"
              className="modal-image"
            />
          </div>
        )}

        {/* Spotlight Section */}
        <div className="spotlight-section">
          <h2>A Supportive Community</h2>
          <div className="spotlight-content">
            <img
              src={meme1}
              alt="meme of indie developer"
              className="side-by-side-image"
              onClick={() => this.setState({ modalImage: meme1 })}
            />
            <p style={{ fontWeight: "600" }}>
              PressPlay is a community of creators who love to make games. We're
              here to help you find your people, get inspired, and build
              something amazing. Discover incredible indie games, talented
              artists, and passionate creators. We're dedicated to showcasing
              the amazing work coming out of our community and helping you make
              meaningful connections.
            </p>
          </div>
        </div>

        {/* Alpha Access Section */}
        <div className="alpha-access">
          <h2>Excited About PressPlay?</h2>
          <p>
            Want to influence the features of the platform? Sign up for alpha
            access and be among the first to explore the game changing benefits
            offered by PressPlay. Your feedback will make a direct impact on the
            future of this community.
          </p>
          <button
            className="alpha-access-button"
            onClick={this.handleEmailRequest}
          >
            Request Alpha Access
          </button>
        </div>
      </div>
    );
  }
}

export default Waitlist;
