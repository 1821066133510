import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { userPool } from "../Cognito";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  async prepareHeaders(headers) {
    const cognitoUser = userPool.getCurrentUser();
    let cognitoCred = "NO API KEY!!";
    if (cognitoUser) {
      cognitoUser.getSession((_, session) => {
        cognitoCred = session.idToken.jwtToken;
        if (!cognitoCred) {
          console.error("No token found!!");
        }
      });
    } else {
      console.error("No user session!!");
    }
    headers.set("Content-Type", "application/json");
    headers.set("Authorization", cognitoCred);
    return headers;
  },
  credentials: "include",
});

const api = createApi({
  baseQuery,
  tagTypes: ["currentUser", "conversations", "messages"],
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      query: () => ({
        url: "/GetCurrentUser",
        method: "GET",
      }),
      providesTags: ["currentUser"],
    }),
    getConversations: builder.query({
      query: () => ({
        url: "/GetConversations",
        method: "GET",
      }),
      providesTags: ["conversations"],
    }),
    startConversation: builder.mutation({
      query: ({ recipientId, initialMessage }) => ({
        url: "/StartConversation",
        method: "POST",
        body: {
          recipientId,
          initialMessage,
        },
      }),
      invalidatesTags: ["conversations"],
    }),
    getMessages: builder.query({
      query: (convoId) => ({
        url: "/GetMessages",
        method: "GET",
        params: {
          convoId,
        },
      }),
      // should refetch on get without cache
      forceRefetch: () => true,
      // providesTags: (res, err, convoId) => [{ type: "messages", id: convoId }],
    }),
  }),
});

export default api;
export const {
  useGetCurrentUserQuery,
  useGetConversationsQuery,
  useStartConversationMutation,
  useGetMessagesQuery,
  useLazyGetMessagesQuery,
} = api;
