import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import SignupWrapper from "./components/SignupWrapper";
import Sidebar from "./components/Sidebar";
import FindTeam from "./pages/FindTeam";
import Header from "./components/Header";
import EditProfile from "./pages/EditProfile";
import Blog from "./pages/Blog";
import Signin from "./pages/Signin";
import UpgradeModal from "./components/UpgradeModal";
import Footer from "./components/Footer";
import { getCurrentUser, userPool } from "./Cognito";
import { useTheme } from "./components/ThemeContext";
import "./App.css";
import { fetchUserProfile } from "./utils/DBCommon";
import { BASE_PATH } from "./utils/constants";
import Waitlist from "./pages/Waitlist";
import { ToastContainer } from "react-toastify";

function App() {
  const { theme, toggleTheme } = useTheme();
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const cognitoUser = userPool.getCurrentUser();
  const [isLoggedIn, setIsLoggedIn] = useState(cognitoUser != null);

  const handleSidebarToggle = (isOpen) => {
    setIsChatExpanded(isOpen);
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const openUpgradeModal = () => setUpgradeModalOpen(true);
  const closeUpgradeModal = () => setUpgradeModalOpen(false);

  return (
    <div className="App">
      <Header onUpgradeClick={openUpgradeModal} />

      <div className="app-content">
        {isLoggedIn && <Sidebar onToggle={handleSidebarToggle} />}{" "}
        {/* Show Sidebar when logged in */}
        <div className={`main-content ${isChatExpanded ? "expanded" : ""}`}>
          <Routes>
            <Route path="/" element={<Waitlist />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route
              path={`${BASE_PATH}/`}
              element={<HomePage isLoggedIn={isLoggedIn} />}
            />
            <Route
              path={`${BASE_PATH}/sign-up`}
              element={<SignupWrapper onLoginSuccess={handleLoginSuccess} />}
            />
            <Route
              path={`${BASE_PATH}/login`}
              element={<Signin onLoginSuccess={handleLoginSuccess} />}
            />

            <Route
              path={`${BASE_PATH}/find-team`}
              element={<FindTeam isChatExpanded={isChatExpanded} />}
            />

            <Route path={`${BASE_PATH}/blog`} element={<Blog />} />
            <Route
              path={`${BASE_PATH}/edit-profile`}
              element={<EditProfile />}
            />
          </Routes>
        </div>
      </div>

      <Footer />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      <UpgradeModal isOpen={isUpgradeModalOpen} onClose={closeUpgradeModal} />
    </div>
  );
}

export default App;
