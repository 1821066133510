import AWS from "aws-sdk";
import config from "../config";

export const fetchUserProfile = async (identityId) => {
  const dynamodb = new AWS.DynamoDB.DocumentClient();
  const params = {
    TableName: config.aws.usersTableName,
    Key: { cognitoId: identityId },
  };

  const result = await dynamodb.get(params).promise();
  return result.Item;
};
