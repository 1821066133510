import React, { useState, useEffect } from 'react';
import '../styles/HomePage.css';
import ArtistSpotlight from '../imgs/artist-spotlight.jpg';
import NarrativeSpotlight from '../imgs/artist-spotlight-2.jpg';
import UnrealLogo from '../imgs/unreal-engine-2.svg';
import UnityLogo from '../imgs/unity.svg';
import GodotLogo from '../imgs/godot.svg';
import BlenderLogo from '../imgs/blender-2.svg';
import GamemakerLogo from '../imgs/gamemaker.svg';
import ZBrushLogo from '../imgs/zbrush.svg';
import featured1 from '../imgs/featured-1.png';
import featured2 from '../imgs/featured-2.png';
import featured3 from '../imgs/featured-3.png';
import newGame1 from '../imgs/newgame-1.png';
import newGame2 from '../imgs/newgame-2.png';
import newGame3 from '../imgs/newgame-3.png';
import newGame4 from '../imgs/newgame-4.png';
import newGame5 from '../imgs/newgame-5.png';
import newGame6 from '../imgs/newgame-6.png';
import { useNavigate } from 'react-router-dom';
import UpgradeModal from '../components/UpgradeModal';
import { useTheme } from "../components/ThemeContext";

function HomePage() {
  const { theme } = useTheme();
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const openUpgradeModal = () => setUpgradeModalOpen(true);
  const closeUpgradeModal = () => setUpgradeModalOpen(false);
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/alpha/find-team');
  };

  return (
    <div className={`app-${theme}`}>
      <section className="home__hero">
        <div id="container1" className="home__hero-bg">
          <h1>Find Your Team</h1>
          <p>Connect. Collaborate. Create.</p>
          <button onClick={handleNavigation} className="cta-upgrade-button">
            Press Play
          </button>
        </div>
      </section>
      <section className="home__logos">
  <div className="logos__container">
    <img src={UnrealLogo} alt="Unreal Engine Logo" className="logo" />
    <img src={BlenderLogo} alt="Unreal Engine Logo" className="logo" />
    <img src={GodotLogo} alt="Unreal Engine Logo" className="logo" />
    <img src={UnityLogo} alt="Unreal Engine Logo" className="logo" />
    <img src={ZBrushLogo} alt="Unreal Engine Logo" className="logo" />
    <img src={GamemakerLogo} alt="Unreal Engine Logo" className="logo" />
    </div>
    </section>
      {/* Features Section */}
      <section className="home__features">
        <h2>Ready to Start?</h2>
        <div className="home__features-list">
          <div className="home__feature">
            <h3>Find Your Team</h3>
            <p>Everyone knows solo dev is nightmare mode. Bring the joy back into your process by partnering with the right people.</p>
          </div>
          <div className="home__feature">
            <h3>Messaging</h3>
            <p>Chat with mutuals or just make connections and follow up later.</p>
          </div>
          <div className="home__feature">
            <h3>Build Your Game</h3>
            <p>With the help of a talented teammate, build your dream game together.</p>
          </div>
        </div>
      </section>
      <section className="artist__spotlight">
        <h2 className="spotlight__title">Artist Spotlight</h2>
        <div className="spotlight__content">
          <img
            src={ArtistSpotlight}
            alt="User Artwork"
            className="spotlight__image"
          />
          <p className="spotlight__text">
            Discover the amazing work of <span className="highlight">Gwen Nguyen</span>, 
            a talented <span className="highlight">3D Modeler</span> creating worlds with passion.
          </p>
        </div>
      </section>

      <section className="featured__members">
        <h2 className="featured__title">This Week's Featured Members</h2>
        <div className="featured__content">
          {/* <div className="featured__member">
            <div className="image-wrapper">
              <img src={featured1} alt="Featured Member 1" className="featured__image" />
            </div>
            {/* <button onClick={openUpgradeModal} className="cta-upgrade-button">
              + Connection
            </button> 
          </div> */}
          <div className="featured__member">
            <div className="image-wrapper">
              <img src={featured2} alt="Featured Member 2" className="featured__image" />
            </div>
          </div>
          <div className="featured__member">
            <div className="image-wrapper">
              <img src={featured3} alt="Featured Member 3" className="featured__image" />
            </div>
            {/* <button onClick={openUpgradeModal} className="cta-upgrade-button">
              + Connection
            </button> */}
          </div>
        </div>
      </section>
      <section className="featured-videos">
      <div className="featured-videos__header">
        <h2 className="featured-videos__title">Featured Content</h2>
      </div>
      <div className="featured-videos__grid">
        <div className="featured-videos__item">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/PEa0f57GtO8?si=hKOblccBnato9usl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p className="video__text">Watch the latest from 3 Gnomes on indie development process.</p>
        </div>
      </div>
      <div className="featured-videos__grid">
        <div className="featured-videos__item">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/rzLx31nXDw0?si=xseFjBC-pOao_cU3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        <p className="video__text">Learn about creating your game design portfolio from everyone's favorite game dev dad.</p>
        </div>
      </div>
    </section>


      {/* Testimonials Section */}
      <section className="home__testimonials">
        <h2>What Our Members Say</h2>
        <p>"I'm really excited to be an early adopter of this platform, excited to make some great games!" - Alex, Unreal Engine Developer</p>
        <p>"It was such a relief not to fight with randos on reddit everytime I ask if someone wants to make a game." - Jamie, GoDot Developer</p>
      </section>
      
      <section className="new-indie-releases">
        <h2 className="section__title">New Indie Releases</h2>
        <div className="releases__grid">
          {/* Row 1 */}
          <div className="release__tile">
            <a href="https://kenney.itch.io/barb" className="release__link">
              <img
                src={newGame1}
                alt="Indie Release 1"
                className="release__image"
              />
            </a>
            <p className="release__text">Barb</p>
          </div>
          <div className="release__tile">
            <a href="https://cosmicvoid.itch.io/neon-hearts-city" className="release__link">
              <img
                src={newGame2}
                alt="Indie Release 2"
                className="release__image"
              />
            </a>
            <p className="release__text">Neon Hearts City</p>
          </div>
          <div className="release__tile">
            <a href="https://stellarnull.itch.io/woncon" className="release__link">
              <img
                src={newGame3}
                alt="Indie Release 3"
                className="release__image"
              />
            </a>
            <p className="release__text">Wonderland Cosa Nostra</p>
          </div>

          {/* Row 2 */}
          <div className="release__tile">
            <a href="https://yongjustyong.itch.io/catsanddice" className="release__link">
              <img
                src={newGame4}
                alt="Indie Release 4"
                className="release__image"
              />
            </a>
            <p className="release__text">Cats & Dice</p>
          </div>
          <div className="release__tile">
            <a href="https://pahammond.itch.io/balloon-jerks" className="release__link">
              <img
                src={newGame5}
                alt="Indie Release 5"
                className="release__image"
              />
            </a>
            <p className="release__text">Balloon Jerks</p>
          </div>
          <div className="release__tile">
            <a href="https://punkcake.itch.io/build-the-sun" className="release__link">
              <img
                src={newGame6}
                alt="Indie Release 6"
                className="release__image"
              />
            </a>
            <p className="release__text">Build The Sun</p>
          </div>
        </div>
      </section>
      <section className="artist__spotlight">
        <h2 className="spotlight__title">Narrative Spotlight</h2>
        <div className="spotlight__content">
          <img
            src={NarrativeSpotlight}
            alt="User Artwork"
            className="spotlight__image"
          />
          <p className="spotlight__text">
            <span className="highlight">Mia Bartlett</span> is an up and coming writer.
            Her flair for <span className="highlight">character dialoage</span> is exceptional.
          </p>
        </div>
      </section>
                  {/* CTA Section */}
     <section className="home__cta">
        <h2>Ready to Build Your Team?</h2>
        <button onClick={handleNavigation} className="cta-upgrade-button">
          Get Started
        </button>
      </section>

      {/* Upgrade Modal */}
      {isUpgradeModalOpen && <UpgradeModal isOpen={isUpgradeModalOpen} onClose={closeUpgradeModal} />}
    </div>
  );
}

export default HomePage;
