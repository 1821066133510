export const checkProfileCompletion = (profile) => {
  if (!profile) return false;
  console.log(profile.photoUrl)
  console.log(profile)
  return [
    profile.photoUrl,
    profile.name,
    profile.bio,
    profile.skills,
    profile.tools,
    profile.experience,
    profile.whatIBring,
  ].every((field) => field != null && field.length > 0);
};
