import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../styles/Header.css";
import { getCurrentUser, signOut } from "../Cognito";
import { fetchUserProfile } from "../authUtils";
import UpgradeModal from "./UpgradeModal";
import config from "../config";
import logo from "../imgs/pressplay-high-resolution-logo-grayscale-transparent.png";
import { Tooltip } from "@mui/material";
import { checkProfileCompletion } from "../utils/util";
import { BASE_PATH } from "../utils/constants";
import { useCurrentUser } from "../utils/APIHooks";
import Spinner from "./Spinner"; // Import the spinner component

const PLACEHOLDER_IMAGE =
  "https://fastly.picsum.photos/id/478/800/1600.jpg?hmac=DZqt2aigkZUGDB3EWXxk0kH-RZV0Yc1ql9WBQp8K60g";

function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [userProfileCompleted, setUserProfileCompleted] = useState(false);
  const [isReady, setIsReady] = useState(false); // Add loading state

  const currentUser = useCurrentUser();

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const toggleProfileDropdown = () => {
    setIsProfileDropdownOpen((prev) => !prev);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProfileDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    signOut();
    setIsLoggedIn(false);
    setCustomerId(null);
    setProfilePhoto(PLACEHOLDER_IMAGE);
    navigate("/");
  };

  useEffect(() => {
    if (currentUser) {
      setCustomerId(currentUser.cognitoId);
      setUserProfileCompleted(checkProfileCompletion(currentUser));
      setProfilePhoto(currentUser.photoUrl || PLACEHOLDER_IMAGE);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    const timer = setTimeout(() => {
      setIsReady(true);
    }, 500);

    return () => clearTimeout(timer); 
  }, [currentUser]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 5);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isWaitlistPage = location.pathname === "/";

  if (!isReady) {
    return <Spinner size="medium" />;
  }

  return (
    <header
      className={`header ${isLoggedIn ? "logged-in" : ""} ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <div className="header__brand">
        <Link to={`${BASE_PATH}`} className="header__logo">
          <img src={logo} alt="PressPlay Logo" className="header__logo-image" />
        </Link>
      </div>
      {!isWaitlistPage && (
        <nav className="header__nav">
          {userProfileCompleted ? (
            <Link to={`${BASE_PATH}/find-team`} className="header__link">
              Find a Team
            </Link>
          ) : (
            <Tooltip title="Please complete your profile">
              <div className="cursor-not-allowed">Find a Team</div>
            </Tooltip>
          )}

          {isLoggedIn ? (
            <>
              <div className="header__profile" ref={dropdownRef}>
                <img
                  src={profilePhoto || PLACEHOLDER_IMAGE}
                  alt="Profile"
                  className="header__profile-icon"
                  onClick={toggleProfileDropdown}
                />
                <div
                  className={`header__profile-dropdown ${
                    isProfileDropdownOpen ? "open" : ""
                  }`}
                >
                  <Link
                    to={`${BASE_PATH}/edit-profile`}
                    className="header__dropdown-item"
                  >
                    Edit Profile
                  </Link>
                  <Link
                    to="#"
                    className="header__dropdown-item"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLogout();
                    }}
                  >
                    Log Out
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to={`${BASE_PATH}/login`} className="header__link">
                Sign In
              </Link>
              <Link to={`${BASE_PATH}/sign-up`} className="header__link">
                Sign Up
              </Link>
            </>
          )}
        </nav>
      )}
      <UpgradeModal
        isOpen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
        customerId={customerId}
      />
    </header>
  );
}

export default Header;
